import React, { useState } from 'react';
import './ContactList.css';

function ContactList() {
  const [contacts, setContacts] = useState([]);

  const addContact = (name, email, phone) => {
    setContacts([...contacts, { name, email, phone }]);
  };

  return (
    <div>
      <form onSubmit={e => {
        e.preventDefault();
        addContact(e.target.name.value, e.target.email.value, e.target.phone.value);
      }}>
        <input name="name" placeholder="Name" required />
        <input name="email" type="email" placeholder="Email" required />
        <input name="phone" type="tel" placeholder="Phone" required />
        <button type="submit">Add Contact</button>
      </form>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
          </tr>
        </thead>
        <tbody>
          {contacts.map((contact, i) => (
            <tr key={i}>
              <td>{contact.name}</td>
              <td>{contact.email}</td>
              <td>{contact.phone}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ContactList;